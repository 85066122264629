import * as R from "ramda"
// import { createSelector } from "reselect"
import { ThunkAction } from "redux-thunk"
import apiClient, { ApiClientCallbackResponse } from "services/apiClient"

import initialState, { StateShape } from "models/initialState"
import { Actions as uiActions } from "models/ui"
import { getNormalizedEventsFromListOfIds } from "models/events"
import { selectProfilesForProvider } from "models/profiles"

import { extractErrorMessages, makeUserFriendlyErrorMessage } from "utils/errors"
import { isStringOrNumber } from "utils/parseUtils"
import { normalizeProvidersFromServer } from "utils/providers"

import { EMAIL_NEEDS_CONFIRMATION_MESSAGE } from "constants/errors"

import { Slug } from "types/common"
import { AuthAction, AuthActionType } from "types/auth"
import { ToastType } from "types/ui"
import { Event, EventsActionType, IFetchEventsFromSlugListSuccess, ProviderSimple, NormalizedEvent } from "types/events"
import {
  ProviderID,
  Provider,
  ProviderCreate,
  ProviderUpdate,
  ProvidersState,
  ProvidersAction,
  ProvidersActionType,
  SingleProviderSuccessAction,
  SingleProviderFailureAction,
  GenericProviderAction,
  CreateProviderAction,
  UpdateProviderAction,
  FetchProviderAction,
  FetchProvidersFromSlugListAction,
  MultipleProvidersSuccessAction,
  SoftDeleteProviderAction,
  NormalizedProvider,
  NormalizedProviderWithEvents,
  NormalizedProviderWithEventsAndProfiles,
} from "types/providers"

const updateStateAfterSuccessfulProviderAction = (state: ProvidersState, action: SingleProviderSuccessAction) => {
  try {
    const normalizedProvider = normalizeProvidersFromServer([action.data])[0]

    return {
      ...state,
      isLoading: false,
      error: null,
      data: {
        ...state.data,
        [normalizedProvider.id]: normalizedProvider,
      },
      providerSlugToIdLookup: {
        ...state.providerSlugToIdLookup,
        [normalizedProvider.slug]: normalizedProvider.id,
      },
      providerSlugToMetaLookup: {
        ...state.providerSlugToMetaLookup,
        [normalizedProvider.slug]: {
          lastFetchedAt: new Date().toISOString(),
          fourOhFour: false,
        },
      },
    }
  } catch (err) {
    console.log({ err })
    return {
      ...state,
    }
  }
}

const updateStateWithEventProviders = (state: ProvidersState, action: IFetchEventsFromSlugListSuccess) => {
  const providers: ProviderSimple[] = R.map(
    (e) => (isStringOrNumber(e.provider) ? state.data[e.provider] : e.provider),
    action.data as Event[]
  )
  const providerData = providers.reduce((acc, p) => {
    acc[p.id] = state.data[p.id] ? state.data[p.id] : p
    return acc
  }, {})
  const providerSlugToIdLookup = providers.reduce((acc, p) => {
    acc[p.slug] = p.id
    return acc
  }, {})
  const providerSlugToMetaLookup = providers.reduce((acc, p) => {
    acc[p.slug] = {
      lastFetchedAt: new Date().toISOString(),
      fourOhFour: false,
    }
    return acc
  }, {})
  return {
    ...state,
    data: {
      ...state.data,
      ...providerData,
    },
    providerSlugToIdLookup: {
      ...state.providerSlugToIdLookup,
      ...providerSlugToIdLookup,
    },
    providerSlugToMetaLookup: {
      ...state.providerSlugToMetaLookup,
      ...providerSlugToMetaLookup,
    },
  }
}

const updateStateAfterFailedEventAction = (state: ProvidersState, action: SingleProviderFailureAction) => {
  return {
    ...state,
    isLoading: false,
    error: action.error,
  }
}

const updateStateAfterSuccessfullyFetchingMultipleProviders = (
  state: ProvidersState,
  action: MultipleProvidersSuccessAction
) => {
  const meta = { lastFetchedAt: new Date().toISOString(), fourOhFour: false }
  const normalizedProviders = action?.data?.length ? normalizeProvidersFromServer(action.data) : []
  const providerSlugToIdLookup = normalizedProviders.reduce((acc, p) => {
    acc[p.slug] = p.id
    return acc
  }, {})
  const providerSlugToMetaLookup = normalizedProviders.reduce((acc, p) => {
    acc[p.slug] = meta
    return acc
  }, {})

  return {
    ...state,
    isLoading: false,
    isUpdating: false,
    error: null,
    data: {
      ...state.data,
      ...R.indexBy(R.prop("id"), normalizedProviders),
    },
    providerSlugToIdLookup: {
      ...state.providerSlugToIdLookup,
      ...providerSlugToIdLookup,
    },
    providerSlugToMetaLookup: {
      ...state.providerSlugToMetaLookup,
      ...providerSlugToMetaLookup,
    },
  }
}

export default function providersReducer(
  state = initialState.providers,
  action: ProvidersAction | AuthAction | IFetchEventsFromSlugListSuccess
) {
  switch (action.type) {
    case ProvidersActionType.FETCH_PROVIDER_BY_ID:
      return { ...state, isLoading: true, fetchingState: "fetching" }
    case ProvidersActionType.FETCH_PROVIDER_BY_ID_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        fetchingState: "fetched",
        data: {
          ...state.data,
          [action.data.id]: action.data,
        },
        providerSlugToIdLookup: {
          ...state.providerSlugToIdLookup,
          [action.data.slug]: action.data.id,
        },
        providerSlugToMetaLookup: {
          ...state.providerSlugToMetaLookup,
          [action.data.slug]: {
            lastFetchedAt: new Date().toISOString(),
            fourOhFour: false,
          },
        },
      }
    case ProvidersActionType.FETCH_PROVIDER_BY_ID_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
        fetchingState: "failed",
      }

    case ProvidersActionType.FETCH_PROVIDER:
      return {
        ...state,
        isLoading: true,
        fetchingState: "fetching",
      }
    case ProvidersActionType.FETCH_PROVIDER_SUCCESS:
      return {
        ...updateStateAfterSuccessfulProviderAction(state, action),
        fetchingState: "fetched",
      }
    case ProvidersActionType.FETCH_PROVIDER_FAILURE:
      return {
        ...updateStateAfterFailedEventAction(state, action),
        fetchingState: "failed",
      }
    case ProvidersActionType.FETCH_PROVIDERS_FROM_SLUG_LIST:
      return { ...state, isLoading: true, fetchingState: "fetching" }
    case ProvidersActionType.FETCH_PROVIDERS_FROM_SLUG_LIST_SUCCESS:
      return {
        ...updateStateAfterSuccessfullyFetchingMultipleProviders(state, action),
        fetchingState: "fetched",
      }
    case ProvidersActionType.FETCH_PROVIDERS_FROM_SLUG_LIST_FAILURE:
      return { ...state, isLoading: false, error: action.error, fetchingState: "failed" }
    case ProvidersActionType.CREATE_PROVIDER:
      return {
        ...state,
        creatingState: "creating",
      }
    case ProvidersActionType.CREATE_PROVIDER_SUCCESS:
      return {
        ...updateStateAfterSuccessfulProviderAction(state, action),
        creatingState: "created",
      }
    case ProvidersActionType.CREATE_PROVIDER_FAILURE:
      return {
        ...updateStateAfterFailedEventAction(state, action),
        creatingState: "failed",
      }
    case ProvidersActionType.UPDATE_PROVIDER:
      return {
        ...state,
        updatingState: "updating",
      }
    case ProvidersActionType.UPDATE_PROVIDER_SUCCESS:
      return {
        ...updateStateAfterSuccessfulProviderAction(state, action),
        updatingState: "updated",
      }
    case ProvidersActionType.UPDATE_PROVIDER_FAILURE:
      return {
        ...updateStateAfterFailedEventAction(state, action),
        updatingState: "failed",
      }
    case ProvidersActionType.SOFT_DELETE_PROVIDER:
      return {
        ...state,
        deletingState: "deleting",
      }
    case ProvidersActionType.SOFT_DELETE_PROVIDER_SUCCESS:
      return {
        ...state,
        error: null,
        data: {
          ...state.data,
          [action.data.id]: null,
        },
        providerSlugToIdLookup: {
          ...state.providerSlugToIdLookup,
          [action.data.slug]: null,
        },
        providerSlugToMetaLookup: {
          ...state.providerSlugToIdLookup,
          [action.data.slug]: null,
        },
        deletingState: "deleted",
      }
    case ProvidersActionType.SOFT_DELETE_PROVIDER_FAILURE:
      return {
        ...state,
        error: action.error,
        deletingState: "failed",
      }
    case ProvidersActionType.SET_PROVIDER_FOUR_OH_FOUR_SLUG:
      return {
        ...state,
        providerSlugToMetaLookup: {
          ...state.providerSlugToMetaLookup,
          [action.slug]: {
            lastFetchedAt: new Date().toISOString(),
            fourOhFour: true,
          },
        },
      }
    case ProvidersActionType.RESET_PROCESSING_STATE:
      return {
        ...state,
        isLoading: false,
        fetchingState: null,
        updatingState: null,
        creatingState: null,
        deletingState: null,
      }
    case EventsActionType.FETCH_EVENTS_FROM_SLUG_LIST_SUCCESS:
      return updateStateWithEventProviders(state, action)
    case AuthActionType.SIGN_USER_OUT:
      return initialState.providers
    default:
      return state
  }
}

/**
 * PROVIDERS ACTIONS
 */
const clearUserProviderState = () => ({ type: ProvidersActionType.CLEAR_USER_PROVIDER_STATE })
const resetProviderProcessingState = () => ({ type: ProvidersActionType.RESET_PROCESSING_STATE })
const setProviderFourOhFourSlug = (slug: Slug) => ({
  type: ProvidersActionType.SET_PROVIDER_FOUR_OH_FOUR_SLUG,
  slug,
  error: "Provider not found.",
})
//  const userProvidersNeedRefresh = () => ({ type: ProvidersActionType.USER_PROVIDERS_NEEDS_REFRESH })

export type CreateProviderParams = { providerCreate: ProviderCreate }
export type CreateProviderResult = ThunkAction<
  Promise<ApiClientCallbackResponse>,
  StateShape,
  void,
  CreateProviderAction
>
const createProvider = ({ providerCreate }: CreateProviderParams): CreateProviderResult => {
  return async (dispatch) => {
    return dispatch(
      apiClient({
        url: `/providers/`,
        method: `POST`,
        types: {
          REQUEST: ProvidersActionType.CREATE_PROVIDER,
          SUCCESS: ProvidersActionType.CREATE_PROVIDER_SUCCESS,
          FAILURE: ProvidersActionType.CREATE_PROVIDER_FAILURE,
        },
        options: {
          data: { provider_create: providerCreate },
          params: {},
        },
        onSuccess: (res) => {
          //  dispatch(Actions.fetchUserProviders())

          return { success: true, status: res.status, data: res.data }
        },
        onFailure: (res) => {
          const error = res?.error?.response && res?.error?.response?.data ? res.error.response.data : res.error

          const errorMessages = extractErrorMessages(error)

          errorMessages.forEach((error) => {
            const toast = {
              title: "Could not create provider",
              contents: makeUserFriendlyErrorMessage(error),
              type: ToastType.DANGER,
            }
            dispatch(uiActions.addUiToast({ toast }))
          })

          return { success: false, status: res.status, error }
        },
      })
    )
  }
}

//  export type FetchUserProvidersResult = ThunkAction<
//    Promise<ApiClientCallbackResponse>,
//    StateShape,
//    void,
//    FetchUserProvidersAction
//  >
//  const fetchUserProviders = (): FetchUserProvidersResult => {
//    return async (dispatch) =>
//      dispatch(
//        apiClient({
//          url: `/providers/me/`,
//          method: "GET",
//          types: {
//            REQUEST: ProvidersActionType.FETCH_USER_PROVIDERS,
//            SUCCESS: ProvidersActionType.FETCH_USER_PROVIDERS_SUCCESS,
//            FAILURE: ProvidersActionType.FETCH_USER_PROVIDERS_FAILURE,
//          },
//          options: {
//            data: {},
//            params: {},
//          },
//          onSuccess: (res) => {
//            return { success: true, status: res.status, data: res.data }
//          },
//          onFailure: (res) => {
//            const error = res?.error?.response && res?.error?.response?.data ? res.error.response.data : res.error
//            // console.log(error)

//            const errorMessages = extractErrorMessages(error)

//            errorMessages.forEach((error) => {
//              const toast = {
//                title: "Something went wrong fetching providers.",
//                contents: makeUserFriendlyErrorMessage(error),
//                type: "danger",
//              }
//              dispatch(uiActions.addUiToast({ toast }))
//            })

//            return { success: false, status: res.status, error }
//          },
//        })
//      )
//  }

export type FetchProviderByIdParams = { providerId: ProviderID }
export type FetchProviderByIdResult = ThunkAction<
  Promise<ApiClientCallbackResponse>,
  StateShape,
  void,
  FetchProviderAction
>
const fetchProviderById = ({ providerId }: FetchProviderByIdParams): FetchProviderByIdResult => {
  return async (dispatch) => {
    return dispatch(
      apiClient({
        url: `/providers/id/${providerId}/`,
        method: "GET",
        types: {
          REQUEST: ProvidersActionType.FETCH_PROVIDER_BY_ID,
          SUCCESS: ProvidersActionType.FETCH_PROVIDER_BY_ID_SUCCESS,
          FAILURE: ProvidersActionType.FETCH_PROVIDER_BY_ID_FAILURE,
        },
        options: {
          data: {},
          params: {},
        },
        onSuccess: (res) => {
          // console.log({ res })
          return {
            type: ProvidersActionType.FETCH_PROVIDER_SUCCESS,
            success: true,
            status: res?.status,
            data: res?.data,
          }
        },
        onFailure: (res) => {
          // console.log({ res })
          return {
            type: ProvidersActionType.FETCH_PROVIDER_FAILURE,
            success: false,
            status: res?.status,
            error: res?.error,
          }
        },
      })
    )
  }
}

export type FetchProviderBySlugParams = { providerSlug: Slug }
export type FetchProviderBySlugResult = ThunkAction<
  Promise<ApiClientCallbackResponse>,
  StateShape,
  void,
  FetchProviderAction | GenericProviderAction
>
const fetchProviderBySlug = ({ providerSlug }: FetchProviderBySlugParams): FetchProviderBySlugResult => {
  return async (dispatch) => {
    return dispatch(
      apiClient({
        url: `/providers/slug/${providerSlug}/`,
        method: "GET",
        types: {
          REQUEST: ProvidersActionType.FETCH_PROVIDER,
          SUCCESS: ProvidersActionType.FETCH_PROVIDER_SUCCESS,
          FAILURE: ProvidersActionType.FETCH_PROVIDER_FAILURE,
        },
        options: {
          data: {},
          params: {},
        },
        onSuccess: (res) => ({ success: true, status: res.status, data: res.data }),
        onFailure: (res) => {
          dispatch(Actions.setProviderFourOhFourSlug(providerSlug) as GenericProviderAction)
          return { success: false, status: res.status, error: res.error }
        },
      })
    )
  }
}

export type FetchProviderListBySlugsParams = { providerSlugs: Slug[] }
export type FetchProviderListBySlugsResult = ThunkAction<
  Promise<ApiClientCallbackResponse>,
  StateShape,
  void,
  FetchProvidersFromSlugListAction | GenericProviderAction
>
const fetchProviderListBySlugs = ({
  providerSlugs,
}: FetchProviderListBySlugsParams): FetchProviderListBySlugsResult => {
  return (dispatch) => {
    const slugs = providerSlugs.map((slug) => `&provider_slug_list=${slug}`).join("")
    const slugParams = `?${slugs.slice(1)}`
    const url = `/providers/by-slugs/${slugParams}`

    return dispatch(
      apiClient({
        url,
        method: "GET",
        types: {
          REQUEST: ProvidersActionType.FETCH_PROVIDERS_FROM_SLUG_LIST,
          SUCCESS: ProvidersActionType.FETCH_PROVIDERS_FROM_SLUG_LIST_SUCCESS,
          FAILURE: ProvidersActionType.FETCH_PROVIDERS_FROM_SLUG_LIST_FAILURE,
        },
        options: {
          data: {},
          params: {},
        },
        onSuccess: (res) => ({ success: true, status: res.status, data: res.data }),
        onFailure: (res) => {
          return { success: false, status: res.status, error: res.error }
        },
      })
    )
  }
}

export type UpdateProviderParams = { provider: Provider; providerUpdate: ProviderUpdate }
export type UpdateProviderResult = ThunkAction<
  Promise<ApiClientCallbackResponse>,
  StateShape,
  void,
  UpdateProviderAction
>
const updateProvider = ({ provider, providerUpdate }: UpdateProviderParams): UpdateProviderResult => {
  return async (dispatch) => {
    return dispatch(
      apiClient({
        url: `/providers/${provider.id}/`,
        method: "PUT",
        types: {
          REQUEST: ProvidersActionType.UPDATE_PROVIDER,
          SUCCESS: ProvidersActionType.UPDATE_PROVIDER_SUCCESS,
          FAILURE: ProvidersActionType.UPDATE_PROVIDER_FAILURE,
        },
        options: {
          data: { provider_update: providerUpdate },
          params: {},
        },
        onSuccess: (res) => {
          const toast = {
            title: "Success!",
            contents: "Provider info has been updated.",
            type: ToastType.SUCCESS,
          }
          dispatch(uiActions.addUiToast({ toast }))

          return { success: true, status: res.status, data: res.data }
        },
        onFailure: (res) => {
          const error = res?.error?.response && res?.error?.response?.data ? res.error.response.data : res.error

          const errorMessages = extractErrorMessages(error)

          errorMessages.forEach((error) => {
            const toast = {
              title: "Could not update provider",
              contents: makeUserFriendlyErrorMessage(error),
              type: ToastType.DANGER,
              link: error === EMAIL_NEEDS_CONFIRMATION_MESSAGE ? `/profile/` : null,
            }
            dispatch(uiActions.addUiToast({ toast }))
          })

          return { success: false, status: res.status, error: res.error }
        },
      })
    )
  }
}

export type SoftDeleteProviderParams = { providerId: ProviderID }
export type SoftDeleteProviderResult = ThunkAction<
  Promise<ApiClientCallbackResponse>,
  StateShape,
  void,
  SoftDeleteProviderAction
>
const softDeleteProvider = ({ providerId }: SoftDeleteProviderParams): SoftDeleteProviderResult => {
  return async (dispatch) => {
    return dispatch(
      apiClient({
        url: `/providers/${providerId}/`,
        method: "DELETE",
        types: {
          REQUEST: ProvidersActionType.SOFT_DELETE_PROVIDER,
          SUCCESS: ProvidersActionType.SOFT_DELETE_PROVIDER_SUCCESS,
          FAILURE: ProvidersActionType.SOFT_DELETE_PROVIDER_FAILURE,
        },
        options: {
          data: {},
          params: {},
        },
        onSuccess: (res) => {
          const toast = {
            title: "Success!",
            contents: "Provider has been deleted.",
            type: ToastType.SUCCESS,
          }
          dispatch(uiActions.addUiToast({ toast }))

          return { success: true, status: res.status, data: res.data }
        },
        onFailure: (res) => {
          const error = res?.error?.response && res?.error?.response?.data ? res.error.response.data : res.error

          const errorMessages = extractErrorMessages(error)

          errorMessages.forEach((error) => {
            const toast = {
              title: "Could not delete provider",
              contents: makeUserFriendlyErrorMessage(error),
              type: ToastType.DANGER,
              link: error === EMAIL_NEEDS_CONFIRMATION_MESSAGE ? `/profile/` : null,
            }
            dispatch(uiActions.addUiToast({ toast }))
          })

          return { success: false, status: res.status, error: res.error }
        },
      })
    )
  }
}

export const Actions = {
  clearUserProviderState,
  resetProviderProcessingState,
  //  clearProvider,
  //  setProviderAsCurrentProvider,
  setProviderFourOhFourSlug,
  //  userProvidersNeedRefresh,
  createProvider,
  //  fetchUserProviders,
  fetchProviderById,
  fetchProviderBySlug,
  fetchProviderListBySlugs,
  updateProvider,
  softDeleteProvider,
}

/**
 * PROVIDER SELECTORS
 */

/**
 * Select a single provider from state using its id
 *
 */
export const selectProviderById = (state: StateShape, providerId: ProviderID): NormalizedProvider => {
  return state.providers.data[providerId]
}

/**
 * Select a single provider from state using its slug
 *
 */
export const selectProviderBySlug = (state: StateShape, slug: Slug): NormalizedProvider => {
  const providerId = state.providers.providerSlugToIdLookup[slug]
  return state.providers.data[providerId]
}

/**
 * Convert a normalized provider and a list of normalized events into a NormalizedProviderWithEvents
 *
 */
export const createNormalizedProviderWithEvents = (
  normalizedProvider: NormalizedProvider,
  normalizedEvents: NormalizedEvent[]
): NormalizedProviderWithEvents => {
  return { ...normalizedProvider, events: normalizedEvents }
}

/**
 * Given a list of valid provider slugs, usese the slug to id lookup to get all the ids
 *
 */
export const getProviderIdsFromListOfSlugs = (state: StateShape, slugs: Slug[]): ProviderID[] => {
  const slugToIdMapping: Record<Slug, ProviderID> = R.pickBy(
    (v, k) => slugs.includes(k),
    state.providers.providerSlugToIdLookup
  )
  return R.values(slugToIdMapping)
}

/**
 * Given a list of provider ids, select all the providers with those ids from state
 *
 */
export const getNormalizedProvidersFromListOfIds = (
  state: StateShape,
  providerIds: ProviderID[]
): NormalizedProvider[] => {
  const idToEventMapping: Record<ProviderID, NormalizedProvider> = R.pick(
    providerIds.map((e) => Number(e)),
    state.providers.data
  )
  return R.values(idToEventMapping)
}

/**
 * Given a list of provider slugs, select all the providers with those slugs from state
 *
 */
export const getNormalizedProvidersFromListOfSlugs = (
  state: StateShape,
  providerSlugs: Slug[]
): NormalizedProvider[] => {
  const providerIds = getProviderIdsFromListOfSlugs(state, providerSlugs)
  return getNormalizedProvidersFromListOfIds(state, providerIds)
}

/**
 * Given a list of provider slugs, select all the providers with those slugs from state
 * Attach any events associated with that provider that are stored in state as well
 *
 */
export const selectProviderListBySlugsWithEvents = (
  state: StateShape,
  providerSlugs: Slug[]
): NormalizedProviderWithEvents[] => {
  return R.map((provider) => {
    return createNormalizedProviderWithEvents(provider, getNormalizedEventsFromListOfIds(state, provider.events ?? []))
  }, getNormalizedProvidersFromListOfSlugs(state, providerSlugs ?? []))
}

/**
 * Get a single provider from state and attach any associated events
 */
export const selectProviderBySlugWithEvents = (state: StateShape, slug: Slug): NormalizedProviderWithEvents => {
  const provider = selectProviderBySlug(state, slug)
  const events = getNormalizedEventsFromListOfIds(state, provider?.events ?? [])

  return createNormalizedProviderWithEvents(provider, events)
}

export const selectProviderBySlugWithEventsAndProfiles = (
  state: StateShape,
  slug: Slug
): NormalizedProviderWithEventsAndProfiles => {
  const provider = selectProviderBySlugWithEvents(state, slug)
  const { admin, members, owner } = selectProfilesForProvider(state, provider as NormalizedProviderWithEvents)

  return { ...provider, admin, members, owner }
}

export const selectRandomOtherProviders = (state: StateShape, providerSlug: Slug) => {
  const providers = R.values(state.providers.data)

  return R.take(
    3,
    R.filter((p) => p.slug !== providerSlug, providers)
  )
}

// export const selectProviderByIdWithEvents = (
//   state: StateShape,
//   providerId: ProviderID
// ): NormalizedProviderWithEvents => {
//   const provider = selectProviderBySlug(state, providerId)

//   if (provider) {
//     const events = getNormalizedEventsFromListOfIds(state, provider.events || [])
//     return createNormalizedProviderWithEvents(provider, events)
//   }

//   return null
// }

// export const selectProvidersBySlugWithEvents

//  export const providerHasAuthedUserAsOwner = (provider: Provider, userId: UserID) =>
//    userIsOwnerOfProvider(provider, userId)
//  export const providerHasAuthedUserAsAdmin = (provider: Provider, userId: UserID) =>
//    userIsAdminOfProvider(provider, userId)
//  export const providerHasAuthedUserAsMember = (provider: Provider, userId: UserID) =>
//    userIsMemberOfProvider(provider, userId)

//  export const getUserOwnedProviders = (state: StateShape): Provider[] => {
//    return R.filter(
//      (provider: Provider) => providerHasAuthedUserAsOwner(provider, selectAuthedUserId(state) as UserID),
//      R.values(state.providers.data)
//    )
//  }
//  export const selectUserOwnedProviders = createSelector([getUserOwnedProviders], (providers) => providers)

//  export const getProvidersUserIsAdminOf = (state: StateShape): Provider[] => {
//    return R.filter(
//      (provider: Provider) => providerHasAuthedUserAsAdmin(provider, selectAuthedUserId(state) as UserID),
//      R.values(state.providers.data)
//    )
//  }
//  export const selectProvidersUserIsAdminOf = createSelector([getProvidersUserIsAdminOf], (providers) => providers)
//  export const getProvidersUserIsMemberOf = (state: StateShape): Provider[] => {
//    return R.filter(
//      (provider: Provider) => providerHasAuthedUserAsMember(provider, selectAuthedUserId(state) as UserID),
//      R.values(state.providers.data)
//    )
//  }
//  export const selectProvidersUserIsMemberOf = createSelector([getProvidersUserIsMemberOf], (providers) => providers)

//  // get the active provider being displayed for the management dashboard
//  export const getActiveProvider = (state: StateShape) =>
//    R.prop(state.providers.activeProviderId as string, state.providers.data)
//  export const selectActiveProvider = createSelector([getActiveProvider], (activeProvider) => activeProvider)
